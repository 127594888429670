import React from "react";
import Main from "../../../ReusableComponents/Main";
import ScrollAnimation from "react-animate-on-scroll";
import "./css/about.css";
import VisionSVG from "../../../assets/svgComponents/visionSVG";
import MissionSVG from "../../../assets/svgComponents/MissionSVG";

function aboutVision() {
  return (
    <Main isSlideShow={true}>
      <div className="givmar">
        <br />
        <br />
        <div className="row">
          <div className="col-sm-4 text-center">
            <VisionSVG height="250" />
          </div>
          <div className="col-sm-8 card w-100 p-0">
            <div className="card-header text-center ">Sanjay Ghodawat Institute (Short Term Courses)</div>
            <div className="card-body">
              
              <h4 className="card-text text-dark">Sanjay Ghodawat Institute (Short Term Courses)</h4>
              <p className="card-text">
              
              (A Unit of Sou.Soushila Dhanchand Ghodawat Charitable Trust's Sanjay Ghodawat Institute )
              Recognized by: MSBTE State Government (Mumbai)
              <table>
                        <tr>
                          <th>Course Name</th>
                          <th>Duration</th>
                          <th>Seats</th>
                          <th>Fee</th>
                        </tr>
                        <tr>
                          <td>ADVANCED DIPLOMA IN INDUSTRIAL SAFETY (IT)</td>
                          <td>1 Years</td>
                          <td>40</td>
                          <td>35,000/-</td>
                        </tr>
                        <tr>
                          <td>DIPLOMA IN PHOTOGRAPHY AND VIDEOGRAPHY</td>
                          <td>1 Years</td>
                          <td>30</td>
                          <td>20,000/-</td>
                        </tr>
                      
              </table>
                     
              </p>
            </div>
          </div>

          
        </div>
        
       </div> 
       <img 
            src={require("../../../img/stc0.jpeg")} 
            className="banner-img" 
            style={{ width: "600px", height: "700px" }} 
            alt="ITI Banner"/>

    <img 
        src={require("../../../img/stc1.jpeg")} 
        className="banner-img" 
        style={{ width: "600px", height: "700px" }} 
        alt="ITI Banner"/>
    </Main>
  );
}

export default aboutVision;
