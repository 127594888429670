const NavLinks = [
  { title: "Department Information", path: "/department/dentc" },
  { title: "HOD Desk", path: "/department/dentc/hoddesk" },
  { title: "Vision & Mission", path: "/department/dentc/vision-mission" },
  { title: "PEO PO PSO", path: "/department/dentc/outcomes" },
  { title: "Faculty Profile", path: "/department/dentc/teaching-staff" },
  { title: "Technical Staff", path: "/department/dentc/teacnical-staff" },
  { title: "Achivements", path: "/department/dentc/achivements" },
  { title: "Activities", path: "/department/dentc/activities" },
  { title: "Academic Calender ", path: "/department/dentc/academic-calender" },
  { title: "Publications", path: "/department/dentc/publications" },
];

export default NavLinks;
