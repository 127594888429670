import React from "react";
import Main from "../../../ReusableComponents/Main";
import DeptMenu from "../Reusables/DeptMenu";
import MechSVG from "../Reusables/SVGIllustrations/MechSVG";
import NavLinks from "./Nav";

const Mech = () => {
  return (
    <Main className="container-lg cse w-100 m-2 p-0">
      <div className="row deptcont">
        <DeptMenu dept="Mechanical" subMenu={NavLinks} />
        <div className="col text-dark w-100">
          <div
            data-spy="scroll"
            className="scrollspy-example z-depth-1 mt-4 w-100 p-3"
            data-target="#navbar-example3"
            data-offset="0"
          >
            <div className="illustration-cont">
              <MechSVG width="300" height="300" />
            </div>
            <p className="m-1">
            Mechanical Engineering is a key branch of engineering, focused on designing and developing various types of machines. Through constant innovation and new discoveries, it has greatly improved the comfort and safety of human life. At SGI, we are proud of our modern infrastructure and well-equipped laboratories, with an investment of over one crore in top-quality equipment. Our labs are built to meet the needs of both industry and research, preparing students to face future challenges. With a strong focus on quality, we have created a department that is competitive on a global level, nurturing a talented group of students and professors. Today, we are proud to contribute to India's future by training skilled and innovative engineers through our Mechanical Engineering program.
            </p>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Mech;
