import React from "react";
import "./CSS/header.css";
const Header = () => {
  return (
    
    <div className="banner">
      <div class="top-bar">
        📞 9256798080 / 230-2463800|
        ⏰ Mon-Sat 8:00 AM to 6:00 PM|
        📧 info@sginstitute.in|
      </div>
      <img src={require("../img/banner.jpg")} className="bannner-img" />
    </div>

   
  );
};
// src="https://firebasestorage.googleapis.com/v0/b/sgpoly-86d3b.appspot.com/o/staticFiles%2Fbanner%20v1.2.jpg?alt=media&token=0bfa5941-9473-41a1-9454-8d9632f1e5c8"
export default Header;
