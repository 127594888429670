import React from "react";
import Main from "../../../ReusableComponents/Main";
import DeptMenu from "../Reusables/DeptMenu";
import NavLinks from "./NavLinks";
import "../Reusables/CSS/DeptHome.css";
import Icon from "./Icon";
const Fy = () => {
  return (
    <Main className="container-lg cse w-100 m-2 p-0">
      <div className="row deptcont">
        <DeptMenu dept="Basic Science and Humanities" subMenu={NavLinks} />
        <div className="col text-dark w-100">
          <div
            data-spy="scroll"
            className="scrollspy-example z-depth-1 mt-4 w-100 p-3"
            data-target="#navbar-example3"
            data-offset="0"
          >
            <div className="illustration-cont">
              <Icon width="300" height="300" />
            </div>
            <p className="m-1 words">
            The Basic Science and Humanities (BSH) department at Sanjay Ghodawat Institute focuses on foundational subjects like Mathematics, Physics, Chemistry, and English. This department is essential for developing students' analytical and communication skills, laying a strong groundwork for engineering and other technical disciplines.

                <br/>The institute is a part of, emphasizes a research-based curriculum and practical skills, with state-of-the-art laboratories and experienced faculty members. The BSH department integrates theoretical knowledge with practical application, supporting the students’ overall academic and professional growth through research opportunities and collaborations with industries and research labs.
              <br />
              <br />
              <br />
            </p>
            <h4 className="m-1 words">
              {" "}
              <b>Salient Features:</b>{" "}
            </h4>
            <p className="m-1 words">
              The department consists of highly qualified and dedicated
              faculties. There are thirteen faculties working in this
              department. This department comprises three spacious, well
              equipped and sophisticated laboratories. The department focuses on
              overall development of students including personality development,
              communication skills, soft skills, etiquettes and manners etc. To
              develop these skills seminars, group wise debate, group
              discussion, writing practices are conducted in each semester. In
              addition to these, Language software is used in professional
              Communication laboratory to develop language skills of students.
              The emphasis is given on basic understanding of subject. <br />{" "}
              Through the training programs by industry personnel, the students
              are made aware of the importance of soft skills in industrial
              area. The interactive methods like power point presentation,
              models, cross tables, question answering games, videos of certain
              topics are used in the lecture hall to increase interest of
              students. Parental affection and care is provided to all students
              by faculty members. Considering new entry of students in to the
              college, counseling sessions are arranged so as to build
              confidence in them. The department is striving hard to achieve
              excellence in academics.
            </p>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Fy;
