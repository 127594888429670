const NavLinks = [
  { title: "Department Information", path: "/department/dmech" },
  { title: "HOD Desk", path: "/department/dmech/hod" },

  { title: "Vission & Mission", path: "/department/dmech/vision-mission" },
  { title: "PEO PO PSO", path: "/department/dmech/outcomes" },
  { title: "Faculty Profile", path: "/department/dmech/teaching-staff" },
  { title: "Technical Staff", path: "/department/dmech/technical-staff" },

  { title: "Achivements", path: "/department/dmech/achivements" },
  { title: "Activities", path: "/department/dmech/activities" },
  { title: "Academic Calender ", path: "/department/dmech/academic-calender" },
  { title: "Publications", path: "/department/dmech/publications" },
];

export default NavLinks;
