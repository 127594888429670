import React from "react";
import NavLinks from "./Nav";
import "../Reusables/CSS/HOD.css";
import Main from "../../../ReusableComponents/Main";
import DeptMenu from "../Reusables/DeptMenu";

const MechanicalHODDesk = () => {
  return (
    <Main className="container-lg cse w-100 m-2 p-0">
      <div className="row deptcont">
        <DeptMenu dept="Mechanical" subMenu={NavLinks} />
        <div className="col text-dark w-100">
          <div
            data-spy="scroll"
            className="scrollspy-example z-depth-1 mt-4"
            data-target="#navbar-example3"
            data-offset="0"
          >
            <div className="row">
              <div className="col text-center">
                <img
                  alt="cse hod"
                  className="img-fluid rounded"
                  src="https://firebasestorage.googleapis.com/v0/b/sgpoly-86d3b.appspot.com/o/staff-picture%2F32abd04a-44c2-454b-92ba-2be6a36b26a0?alt=media&token=e0277170-61fa-4b9e-84d2-4f7bc444869a"
                />
              </div>
              <div className="col-12 text-center">
                <b>Mr. Thikane Swapnil J.</b>
              </div>
              <div className="col-12 text-center">
                <b>Head of Department</b>
              </div>
            </div>
            <p className="m-1 words">
            The Mechanical Engineering Department, established in 2024, currently has intake capacity of 60 students. As a fundamental branch of engineering, it focuses on the design, development, and production of physical devices and systems. The department is equipped with state-of-the-art facilities, including a Machine Shop & Workshop, 3D Printing and Additive Manufacturing Lab, Fluid Machinery Lab, Thermodynamics Lab, Theory of Machines Lab, Material Science and Metallurgy Lab, etc.

In addition to academics, the department organizes various activities to enhance students' soft skills, such as Personality Development Workshops, Paper Presentations, Industrial Visits, and Expert Lectures, preparing students for real-world challenges.

Mechanical engineers apply principles of motion, energy, and force to design and manufacture a wide variety of products. It is a creative profession where students are trained to design, prototype, test, and operate mechanical, thermal, and mechatronic systems. Today’s engineers may work on developing robotic systems, creating electric car prototypes, designing turbojets, advancing medical devices like mechanical heart valves, or fabricating components for micro and nano-electromechanical systems.

At Sanjay Ghodawat Institute, the Mechanical Engineering program focuses on producing highly skilled engineers ready for the challenges of the profession. The curriculum emphasizes core engineering principles and equips students to solve complex problems. Throughout their studies, students are trained to design and conduct experiments, ensuring they are prepared to make meaningful contributions in the field of Mechanical Engineering. 
              <br />
            </p>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default MechanicalHODDesk;
